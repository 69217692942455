import React from 'react';

import {
  Container,
  List,
  Responsive,
  Segment,
  Header,
} from 'semantic-ui-react';

import { Link } from 'gatsby';

import Layout from '../components/Layout';
import PhoneNumber from '../components/PhoneNumber';
import SEO from '../components/SEO';

const Contact = () => (
  <Layout>
    <SEO
      title="Contact Us"
      keywords={[
        'duck',
        'hunt',
        'Georgia',
        'Hartwell',
        'Richardson',
        'Contact',
        'Phone',
        'email',
      ]}
    />
    <Responsive>
      <Segment placeholder style={{ padding: '4em 0em' }} vertical>
        <Container text>
          <Header>Methods of Contact</Header>
          <List>
            <List.Item
              icon="map marker alternate"
              content={<Link to="directions">Directions</Link>}
            />
            <List.Item
              icon="mail"
              content={
                <a href="mailto:therichardsonpreserve@gmail.com">Email Us</a>
              }
            />
            <List.Item icon="phone" content={<PhoneNumber />} />
          </List>
        </Container>
      </Segment>
    </Responsive>
  </Layout>
);

export default Contact;
